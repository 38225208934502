.full-height {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content-wrap {
    margin-top: 75px;
    flex: 1;
  }
  
  .footer {
    flex-shrink: 0;
  }
  